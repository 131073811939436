.careercenter6{
    margin-top: 5%;
}
.careerimage6{
    width: 100%;
}
.careerimage6mb{
  width: 0%;
}
.careercenter6text1{
    position: absolute;
    font-family: 'Raleway',sans-serif;
    text-align:left;
    margin-left: 8%;
  margin-top: -13%;
    font-size:3vw;
    font-weight: 700;
    color: #FFFFFF;}
    
.careercenter6text2{
    position: absolute;
    font-family: 'Raleway',sans-serif;
    text-align:left;
    margin-left: 8%;
  margin-top: -10%;
    font-size:3vw;
    font-weight: 700;
    color: #FFFFFF;
  }
    .careercenter6text3{
        position: absolute;
        font-family: 'Barlow Condensed';

        text-align:left;
        margin-left: 8%;
      margin-top: -6%;
        font-size:1.8vw;
        font-weight: 300;
        color: #FFFFFF;}    
.resumesub{
    text-align: center;
    position: absolute;
    font-family: 'Raleway',sans-serif;
    border: 3px solid rgba(217, 4, 61, 1);
    padding: 1%;
    margin-left: 68%;
  margin-top: -10%;
    font-size:1.7vw;
    font-weight: 700;
    color: #FFFFFF;
    cursor: pointer;
}
@media only screen and (max-width: 750px) {
  .careerimage6{
    width: 0%;
}
.careerimage6mb{
  width: 100%;
}
.careercenter6text1{

  margin-left: 13%;
margin-top: -30%;
  font-size:5vw;
  font-weight: 700;
  color: #FFFFFF;}
  
.careercenter6text2{
  margin-left: 18%;
margin-top: -25%;
  font-size:5vw;

}
  .careercenter6text3{
      position: absolute;
      font-family: 'Barlow Condensed';
   
      text-align:left;
      margin-left: 14%;
    margin-top: -19%;
      font-size:3vw;}    
.resumesub{
  text-align: center;
  position: absolute;
  font-family: 'Raleway',sans-serif;
  border: 3px solid rgba(217, 4, 61, 1);
  padding: 1%;
  margin-left: 34%;
margin-top: -12%;
  font-size:3vw;

}
}