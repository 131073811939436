 
hr.brandline2mb {
    position: relative;
    margin-left: 10%;
    width: 80%;
    border: 2px solid rgba(217, 4, 61, 1) ;
    
    margin-top: 19%;
  }
.slasttext1brand3{

    margin-top: 24%;
    font-weight: 700;
    font-size: 2.5vw;
    font-family: 'Raleway',sans-serif;
  
      text-align: center;
      color:rgba(37, 55, 89, 1);
}
.slasttext1brand{

    margin-top: 6%;
    font-weight: 700;
    font-size: 2.5vw;
    font-family: 'Raleway',sans-serif;
  
      text-align: center;
      color:rgba(37, 55, 89, 1);
}
.slasttext2brand{
    
    font-weight: 400;
    font-size: 1.3vw;
    font-family: 'Barlow Condensed',sans-serif;
  margin-top: -.8%;
      text-align: center;
      color:rgba(0, 0, 0, 1);

}

.brand2centermbcol1{
      width: 0%;
      position: absolute;
}
.loyaltymb,.loyaltymb2{
    position: absolute;

    margin-top: 0%;
    width: 0%;
    margin-left: 0%;
}
.loyaltytext1mb{
    position: absolute;
font-size: 0px;
    }
    .loyaltytext2mb{
    font-size: 0px;    
    position: absolute;      }

@media only screen and (max-width: 750px) {
 
    hr.brandline2mb {
        position: relative;
        margin-left: 10%;
        width: 80%;
        border: 2px solid rgba(217, 4, 61, 1) ;
        
        margin-top: 5%;
      }
    .slasttext1brand,.slasttext1brand3{
    
        margin-top: 6%;
        font-weight: 700;
        font-size: 5vw;
        font-family: 'Raleway',sans-serif;
      
          text-align: center;
          color:rgba(37, 55, 89, 1);
    }
    .slasttext2brand{
        
        font-weight: 400;
        font-size: 2vw;
        font-family: 'Barlow Condensed',sans-serif;
      margin-top: -3%;
      margin-bottom: 4%;
          text-align: center;
          color:rgba(0, 0, 0, 1);
    
    }
 
    .brand2centermbcol1{
        position: relative;
        float: left;
        width: 47%;
    }
    .loyaltymb{
        position: relative;
        

        margin-top: 5%;
        width: 30%;
        margin-left: 35%;
    }
    .loyaltymb2{
        
        position: relative;
        margin-top: 5%;
        width: 15%;
        margin-left: 40%;
    }
    .loyaltytext1mb{

        position: relative;
        font-family: 'Barlow Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 4vw;
        align-items: center;
        
        text-transform: capitalize;
        color: #253759;
        margin-left: 35%;
        margin-top: .5%;
        }
        .loyaltytext2mb{
            position: relative;
            font-family: 'Barlow Condensed';
            font-style: normal;
           margin-top: -8%;
           margin-left: 10%;
            font-weight: 400;
            font-size: 3vw;
            text-align: center;
            color: #343434;
                }
        
}