.navbarlogo{
  width: 20%;
  margin-top: 2%;
  margin-left: 8%;
}
.arrow{
  /* background: red; */
  position: absolute !important;
  height: 100%;
  width: 18%;
  margin-left: 209% !important;
  transform: rotate(90deg);
margin-top: -15% !important;
  color:black;
  background-color: white !important;
  border: none !important;
  transition: all 0.3s ease;
}
.listyle .arrow:hover{
  transform: rotate(90deg);
  color: #D9043D;

  margin-top: -15% !important;
  }

.bgcolumn {
  float: left;
  width: 50%;

}


/* Clear floats after the columns */
.bgrow:after {
  content: "";
  display: table;
  clear: both;
}

.linenav{
  border: 3px solid rgba(217, 4, 61, 1);
  width: 80%;

}


.modal-content2{
  height: 100vh;
  width: 100%;
  margin-top: -8%;
  padding-top: 10%;
background-color: #f1f1f1;  
}

  .modal-body{
    margin-top: 0%;

background-color: white;
  }
  #myModal2{
    margin-top: -2%;
  }
  .modal-body2{

  }
  .modal-content{
width: 0%;

box-shadow: none !important;
border:none !important;
margin-top:-2%;
  }
  
  .modal-lg{
   max-width: 100% !important;
   height: 100vh;
   width: 100%;

   padding-top: 10%;
 background-color: white;  
  }
li{
  list-style-type: none;
}


.listyle{
  font-family: 'Raleway',sans-serif;
  font-weight: 700;

  font-size: 1.7vw;
  color: rgba(52, 52, 52, 1);
  margin-left: 15%;

}
.active, .listyle:hover{
 color: #D9043D ;
  text-decoration: none;
}
.modaltextnav{
  position: absolute;
  font-family: 'Raleway',sans-serif;
 font-weight: 700;
  font-size: 1.8vw;
  color: #343434;
    margin-left: 35%;
margin-top: -2%;
    text-align: left;
 
}
.dropbtn {
  font-family: 'Raleway',sans-serif;
 font-weight: 700;
  font-size: 2vw;
  color: rgba(52, 52, 52, 1);
  margin-left: 25%;
  text-align: left;
 
}
.dropdown {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  
  background-color: white ;
 min-width: 500%;
 margin-top: -50%;
  margin-left:223%;
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
  z-index: 1;
  
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {background-color: #f1f1f1;}


.dropdown:hover .dropdown-content {
  opacity: 1;
  transform: translateY(0px);
  pointer-events: auto;
  display: block;
  }
.boxnavtext1{
  font-size: 1.3vw;
  font-family: 'Raleway';
  font-weight: 700;
 cursor: pointer;

margin-left: 9%;
}
.boxnavtext1{

}


.boxnavtext2{
  font-size: .9vw;
  font-family: 'Raleway';
  padding-top: 1.5%;
  padding-bottom: 1%;
  font-weight: 500;
  margin-left: 12%;
  color: #343434;
  margin-top: -5%;
}

.boxnav{
width: 60%;
margin-top: 0%;
background: #F8F8F8;
box-shadow: 6px 4px 6px rgba(0, 0, 0, 0.25);
color: #343434;
padding: .3%;
margin-left: 10%;
float: left;
}
.boxnav:hover{
border-top: 3px solid #D9043D;
color: #D9043D;
}
.boxnav2{

margin-left: -24%;
  width: 60%;
background: #F8F8F8;
color: #343434;
box-shadow: 6px 4px 6px rgba(0, 0, 0, 0.25);
padding: .3%;
}

.boxnav2:hover{
  border-top: 3px solid #D9043D;
  color: #D9043D;
  }
.boxnav3{
width: 60%;
margin-top: 3%;
background: #F8F8F8;
box-shadow: 6px 4px 6px rgba(0, 0, 0, 0.25);
color: #343434;
padding: .3%;
margin-left: 10%;
}

.boxnav3:hover{
  border-top: 3px solid #D9043D;
  color: #D9043D;
  }
.boxnav4{


margin-left: -24%;
margin-top: 3%;
  width: 60%;
background: #F8F8F8;
color: #343434;
box-shadow: 6px 4px 6px rgba(0, 0, 0, 0.25);
padding: .3%;
}

.boxnav4:hover{
  border-top: 3px solid #D9043D;
  color: #D9043D;
  }
.boxnav5{

width: 60%;
margin-top: 3%;
background: #F8F8F8;
box-shadow: 6px 4px 6px rgba(0, 0, 0, 0.25);
color: #343434;
padding: .3%;
margin-left: 10%;
}

.boxnav5:hover{
  border-top: 3px solid #D9043D;
  color: #D9043D;
  }
.boxnav6{


margin-left: -23%;
margin-top: 3%;
  width: 60%;
background: #F8F8F8;
color: #343434;
box-shadow: 6px 4px 6px rgba(0, 0, 0, 0.25);
padding: .3%;
}

.boxnav6:hover{
  border-top: 3px solid #D9043D;
  color: #D9043D;
 
  }
.close3{
  position: absolute;
  width: 1.5%;
  margin-left: 94%;
  margin-top: -3%;
}
.close3:hover{
  opacity: .3;
}
.m1 {
  width: 25%;

  background: #D9043D; 
  margin-top: 3.6%;
  margin-left: 6%;
  border: 2px solid #D9043D;
  border-radius: 1000px;
}
.m2 {
text-align:right;
  width: 30%;
  height: .4vh;
  
  background: #D9043D;
   margin-top: 5%;
   border-radius: 1000px;

}
.m3 {

  width: 20%;
  height: .4vh;
  background: #D9043D;
  margin-top: 5%;
  margin-left: 10%;
  border-radius: 25px;

}

.mtext{
  font-family: 'Raleway';
  font-weight: 700;
font-size: 1.5vw;
  color: #D9043D;
  margin-top: -21%;
  margin-left: 34%;

}
@media only screen and (max-width: 750px) {
  .m1,.m2,.m3{
    width: 0px;
  }
}