.careercenter5text1{
    font-family: 'Raleway',sans-serif;
    text-align:left;
    margin-left: 12%;
  margin-top: 3%;
    font-size:4vw;
    font-weight: 700;
    color: #253759;
}
.careercenter5text2{
    font-family: 'Raleway',sans-serif;
    text-align:left;
    margin-left: 55%;
  margin-top: 3%;
    font-size:3vw;
    font-weight: 700;
    color: #253759;
}
.careercenter5text3{
    font-family: 'Barlow Condensed';
    text-align:right;
    margin-right: 21%;
  margin-top: -1%;
    font-size:1.7vw;
    font-weight: 400;
    color: #000000;}

.boxtext1{
    font-family: 'Raleway',sans-serif;
    text-align:left;
    margin-left: 12%;
  margin-top: 3%;
    font-size:2.5vw;
    font-weight: 700;
    color: #253759;

}
#fclock{
  font-size:1.2vw !important;
  color:rgba(217, 4, 61, 1) !important;
  margin-left:4% !important;
}
.boxmini{
    width: 68%;
    margin-left: 12%;
    border: 1px solid rgba(217, 4, 61, 1);
    padding-bottom: 2%;
}
.boxminitext1{
    font-family: 'Barlow Condensed';
    text-align:left;
    margin-left: 4%;
  margin-top: 1.5%;
    font-size:1.7vw;
    font-weight: 500;
    color: rgba(37, 55, 89, 1);

}
.boxminitext2{
 position: absolute;
    font-family: 'Barlow Condensed';
    text-align:left;
    margin-left: 4.5%;
  margin-top: -1.7%;
    font-size:1.3vw;
    font-weight: 400;
    color: rgba(37, 55, 89, 1);

}
#fmap{
  position: absolute;
font-size:1.2vw !important;
color:rgba(217, 4, 61, 1) !important;
margin-top: -1% !important;
margin-left:33.5% !important;

}

.boxminitext3{
    position: absolute;
       font-family: 'Barlow Condensed';
       text-align:left;
       margin-left: 39%;
     margin-top: -2.7%;
       font-size:1.3vw;
       font-weight: 500;
       color: rgba(37, 55, 89, 1);
   
   }
   .careerarrow{
    position: absolute;
    width: 1.5%;
    margin-top: -1.1%;
    margin-left: 60%;
    cursor: pointer;
   }

   @media only screen and (max-width: 750px) {
    .careercenter5text1{
       margin-left: 5%;
    margin-top: 3%;
      font-size:8vw;
       }
  .careercenter5text2{
      margin-left: 45%;
    margin-top: 3%;
      font-size:6vw;

  }
  .careercenter5text3{

      margin-right: 6%;
    margin-top: -4%;
      font-size:3vw;
}
.boxtext1{
  margin-left: 5%;
margin-top: 3%;
  font-size:4vw;

}
.boxmini{
  width: 90%;
  margin-left: 5%;
  border: 1px solid rgba(217, 4, 61, 1);
  padding-bottom: 5%;
}
.boxminitext1{
   margin-left: 4%;
margin-top: 1.5%;
  font-size:3vw;


}
#fclock{
  position: absolute;
  font-size:3vw !important;
  margin-top: -1.3% !important;
  color:rgba(217, 4, 61, 1) !important;
  margin-left:4% !important;
}
.boxminitext2{
position: absolute;
  font-family: 'Barlow Condensed';
  text-align:left;
  margin-left: 9%;
margin-top: -2.2%;
  font-size:3vw;
  font-weight: 400;
  color: rgba(37, 55, 89, 1);

}
#fmap{
  position: absolute;
  margin-top: -2.2% !important;
  font-size:3vw !important;
  color:rgba(217, 4, 61, 1) !important;
  margin-left:45% !important;
  }
.boxminitext3{
  position: absolute;
     font-family: 'Barlow Condensed';
     text-align:left;
     margin-left: 49%;
   margin-top: -3%;
     font-size:3vw;
     font-weight: 500;
     color: rgba(37, 55, 89, 1);
 
 }
 .careerarrow{
  position: absolute;
  width: 4%;
  margin-top: -3%;
  margin-left:73%;
  cursor: pointer;
 }

   
  }