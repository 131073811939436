/* Googlefont Poppins CDN Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
.headerlogomb{
    position: absolute;
    width: 15%;
}
.hmblogin{
    position: absolute;
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 10px;
    color: #104E71;
    text-transform: uppercase;
   margin-left: 43%;
   margin-top: 3.8%;
}
.hmbgetstart{

    position: absolute;
    text-align: center;
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #104E71;
    border-radius: 5px;
width: 20%;
padding: .5%;
margin-left: 54%;
margin-top: 4%;
}
body{
  min-height: 100vh;
}
nav{
  display: none; }
nav .navbar{
  display: none;
}
.navbar .logo a{
  display: none;}
nav .navbar .nav-links{
  display: none;
}
nav .navbar .links{
  display: none;
}
nav .navbar .links li{
  display: none; }
nav .navbar .links li a{
  height: 0%;
  text-decoration: none;
  white-space: nowrap;
  color: black;
  font-size: 0px;
  font-weight: 0;
}
.links li:hover .htmlcss-arrow,
.links li:hover .js-arrow{
  transform: rotate(0deg);
  }

nav .navbar .links li .arrow{
  display: none; }
nav .navbar .links li .sub-menu{
  display: none;
}
nav .navbar .links li:hover .htmlCss-sub-menu,
nav .navbar .links li:hover .js-sub-menu{
  display: block;
}
.navbar .links li .sub-menu li{
  display: none; }
.navbar .links li .sub-menu a{
  display: none;
}
.navbar .links li .sub-menu .more-arrow{
  line-height: 0px;
}
.navbar .links li .htmlCss-more-sub-menu{
  /* line-height: 40px; */
}
.navbar .links li .sub-menu .more-sub-menu{
  display: none;
   }
.links li .sub-menu .more:hover .more-sub-menu{
  display: none;  }
  .navbar .nav-links .sidebar-logo{
  display: none;
}
.navbar .bx-menu{
  display: none;
}
@media (max-width:920px) {
  nav .navbar{
    max-width: 100%;
    padding: 0 25px;
  }

  nav .navbar .logo a{
    font-size: 27px;
  }
  nav .navbar .links li{
    padding: 0 10px;
    white-space: nowrap;
  }
  nav .navbar .links li a{
    font-size: 15px;
  }
}
@media (max-width:800px){
  nav{
    /* position: relative; */
    background: #F8FAF9;
    color: black;
  }
  .navbar .bx-menu{
    display: block;
    margin-left: 90%;
  color: #104E71 !important;

  }
  nav .navbar .nav-links{
    position: fixed;
    top: 0;
    left: -100%;
    display: block;
    max-width: 270px;
    width: 100%;
    background: #F8FAF9;
    line-height: 40px;
    padding: 20px;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
    z-index: 1000;
  }
  .navbar .nav-links .sidebar-logo{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sidebar-logo .logo-name{
    font-size: 15px;
    color: black;
  }
    .sidebar-logo  i,
    .navbar .bx-menu{
      font-size: 30px;

    }
  nav .navbar .links{
    display: block;
    margin-top: 20px;
  }
  nav .navbar .links li .arrow{
    line-height: 40px;
  }
nav .navbar .links li{
    display: block;
  }
nav .navbar .links li .sub-menu{
  position: relative;
  top: 0;
  box-shadow: none;
  display: none;
}
nav .navbar .links li .sub-menu li{
  border-bottom: none;

}
.navbar .links li .sub-menu .more-sub-menu{
  display: none;
  position: relative;
  left: 0;
}
.navbar .links li .sub-menu .more-sub-menu li{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.links li:hover .htmlcss-arrow,
.links li:hover .js-arrow{
  transform: rotate(0deg);
  }
  .navbar .links li .sub-menu .more-sub-menu{
    display: none;
  }
  .navbar .links li .sub-menu .more span{
    /* background: red; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }

  .links li .sub-menu .more:hover .more-sub-menu{
    display: none;
  }
  nav .navbar .links li:hover .htmlCss-sub-menu,
  nav .navbar .links li:hover .js-sub-menu{
    display: none;
  }
.navbar .nav-links.show1 .links .htmlCss-sub-menu,
  .navbar .nav-links.show3 .links .js-sub-menu,
  .navbar .nav-links.show2 .links .more .more-sub-menu{
      display: block;
    }
    .navbar .nav-links.show1 .links .htmlcss-arrow,
    .navbar .nav-links.show3 .links .js-arrow{
        transform: rotate(180deg);
}
    .navbar .nav-links.show2 .links .more-arrow{
      transform: rotate(90deg);
    }
}
@media (max-width:370px){
  nav .navbar .nav-links{
  max-width: 100%;
} 
}
