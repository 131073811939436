.landcenter1{
  width: 0%;
}
.landcenter11{
  width: 100%;

}
.bgslide{
  width: 100%;
}
.float2-container2{
    margin-top: -.3%;
    width: 100%;
   
  }
  .float2-child2 {
    width: 50%;
    float: left;
     
  }
  .ctxt1{
    margin-top: 10%;
    text-align: left;
    margin-left: 8%;
    font-weight: 700;
    font-family: 'Barlow Condensed',sans-serif;
    font-size: 13.177159590043924vw;
     color: #D9043D;
  }
  
  .ctxt2{
    margin-top: -9%;
    text-align: left;
    margin-left: 8%;
    font-weight: 700;
    font-family: 'Raleway',sans-serif;
    font-size: 2.581844802342606vw;
     color: #253759;
  }
  
  .ctxt2brek{
    margin-top: -3.8%;
    text-align: left;
    margin-left: 8%;
    font-weight: 700;
    font-family: 'Raleway',sans-serif;
    font-size: 2.581844802342606vw;
     color: #253759;
  }
  .ctxt3{
    margin-top: -2%;
    text-align: left;
    margin-left: 8%;
    font-weight: 400;
    font-family: 'Barlow Condensed',sans-serif;
    font-size: 1.4vw;
     color: #343434;
  }
  .readmore{
    

    text-align: left;
    margin-left: 8%;
    font-weight: 600;
    font-family: 'Raleway',sans-serif;
    font-size: 1.171303074670571vw;
     color: #343434;
     float: left;
  }
  .readmorelogo{

    width: 1.5%;
margin-top: .8%;
    margin-left: 2%;
    transform: rotate(-360deg);
}
hr.linemain{
    margin-top: 2%;
    border: 2px solid rgba(217, 4, 61, 1);
    margin-left:8%;
  width: 50%;
}
  .cimage1{
    width: 88%;
    margin-top: 10%;
    margin-left: 2%;
    

  }
  @keyframes fade-right {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-left {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-bottom {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .active.fade-bottom {
    animation: fade-bottom 1s ease-in;
  }
  .active.fade-left {
    animation: fade-left 1s ease-in;
  }
  .reveal {
    position: relative;
    opacity: 0;
  }
  
  .reveal.active {
    opacity: 1;
  }
  
  .active.fade-right {
    animation: fade-right 1s ease-in;
  }  

  .float3-container3{

    width: 99.99%;
    clear: both;

  }
  .float3-child3 {
    width: 50%;
    float: left;
     
  }
  .cimage2{
    width: 88%;
    margin-top: 10%;
    margin-left: 8%;
  }


  .ctxt11{
    margin-top: 5%;
    text-align: right;
    margin-right: 10%;
    font-weight: 700;
    font-family: 'Barlow Condensed',sans-serif;
    font-size: 13.177159590043924vw;
     color: #D9043D;
  }
  
  .ctxt22{
    margin-top: -9%;
    text-align: right;
    margin-right: 10%;
    font-weight: 700;
    font-family: 'Raleway',sans-serif;
    font-size: 2.581844802342606vw;
     color: #253759;
  }
  .ctxt22brek{
    margin-top: -3.8%;
    text-align: right;
    margin-right: 10%;
    font-weight: 700;
    font-family: 'Raleway',sans-serif;
    font-size: 2.581844802342606vw;
     color: #253759;
  }
  .ctxt33{
    margin-top: -1%;
    text-align: right;
    margin-right: 10%;
    font-weight: 400;
    font-family: 'Barlow Condensed',sans-serif;
    font-size: 1.4vw;
     color: #343434;
  }
  .readmore2{
    
    margin-top: 2%;
    text-align: right;
    margin-left: 78%;
    font-weight: 600;
    font-family: 'Raleway',sans-serif;
    font-size: 1.171303074670571vw;
     color: #343434;
     float: left;
  }
  .readmorelogo2{

    width: 1.5%;
    margin-top: 2.5%;
    margin-left: -16%;
    transform: rotate(-180deg);
}
hr.line2{
    margin-top: 2%;
    border: 2px solid rgba(217, 4, 61, 1);
    margin-left: 40%;
  width: 50%;
}

.float4-container4{

    width: 99.98%;

   
  }
  .float4-child4 {
    width: 50%;
    float: left;
     
  }

  .ctxt1mb{
    font-size: 0vw;
    }
  
  .ctxt2mb{
    font-size: 0vw;
  }
  .ctxt3mb{
    font-size: 0vw;

  }
  .readmoremb{
    

    font-size: 0vw;
    }
  .readmorelogomb{

    width: 0%;
   }
hr.linemainmb{
     border: 0px solid rgba(217, 4, 61, 1);
     width: 0%;
}
  .cimagemb{
    width: 0%;
    }

 
@media only screen and (max-width: 750px) {
  .landcenter1{
    width: 100%;
  }
  .landcenter11{
    width: 0%;
  }
  
  .float2mb-child2mb {
    width: 100%;
    float: left;
     
  }
  
  .ctxt1{
    font-size: 0vw;
   
  }
  .ctxt2{
    font-size: 0vw;
   }
  .ctxt3{
    font-size: 0vw;
  }
  .readmore{
    
font-size: 0vw;
  }
  .readmorelogo{
width: 0%;
}
hr.linemain{
    width: 0%;
    border: 0px solid rgba(217, 4, 61, 1);
  
}
  .cimage1{
    width: 0%;
    margin-top: 0%;
    margin-left: 0%;
  }

  .ctxt1mb{
    margin-top: -3%;
    text-align: left;
    margin-left: 8%;
    font-weight: 700;
    font-family: 'Barlow Condensed',sans-serif;
    font-size: 22vw;
     color: #D9043D;
  }
  
  .ctxt2mb{
    margin-top: -9%;
    text-align: left;
    margin-left: 8%;
    font-weight: 700;
    font-family: 'Raleway',sans-serif;
    font-size: 5vw;
     color: #253759;
  }
  .ctxt2mbbr{
    margin-top: -5%;
    text-align: left;
    margin-left: 8%;
    font-weight: 700;
    font-family: 'Raleway',sans-serif;
    font-size: 5vw;
     color: #253759;
  }
  .ctxt3mb{
    text-align: left;
    margin-left: 8%;
    font-weight: 400;
    font-family: 'Barlow Condensed',sans-serif;
    font-size: 3.5vw;
     color: #343434;
  }
  .readmoremb{
    

    text-align: left;
    margin-left: 8%;
    font-weight: 600;
    font-family: 'Raleway',sans-serif;
    font-size: 3.5vw;
     color: #343434;
     float: left;
  }
  .readmorelogomb{

    width: 2%;
    margin-top: 1.3%;
    margin-left: 2%;
    transform: rotate(-360deg);
}
hr.linemainmb{
    margin-top: 2%;
    border: 2px solid rgba(217, 4, 61, 1);
    margin-left:8%;
  width: 50%;
}
  .cimagemb{
    width: 88%;
    margin-top: 10%;
    margin-left: 6%;
  }

  .float3-child3 {
    width: 100%;
    float: left;
     
  }
  .cimage2{
    width: 88%;
    margin-top: 10%;
    margin-left: 6%;
  }


  .ctxt11{
    margin-top: -4%;
    text-align: right;
    margin-right: 10%;
    font-weight: 700;
    font-family: 'Barlow Condensed',sans-serif;
    font-size: 22vw;
     color: #D9043D;
  }
  
  .ctxt22{
    margin-top: -9%;
    text-align: right;
    margin-right: 10%;
    font-weight: 700;
    font-family: 'Raleway',sans-serif;
    font-size: 5vw;
     color: #253759;
  }
  .ctxt22brek{
    margin-top: -5%;
    text-align: right;
    margin-right: 10%;
    font-weight: 700;
    font-family: 'Raleway',sans-serif;
    font-size: 5vw;
     color: #253759;
  }
  .ctxt33{
    margin-top: -2.5%;
    text-align: right;
    margin-right: 10%;
    font-weight: 400;
    font-family: 'Barlow Condensed',sans-serif;
    font-size: 3.5vw;
     color: #343434;
  }
  .readmore2{
    
    margin-top: 2%;
    text-align: right;
    margin-left: 72%;
    font-weight: 600;
    font-family: 'Raleway',sans-serif;
    font-size: 3.5vw;
     color: #343434;
     float: left;
  }
  .readmorelogo2{

    width: 2%;
    margin-top: 3.3%;
    margin-left: -24%;
    transform: rotate(-180deg);
}
hr.line2{
    margin-top: 2%;
    border: 2px solid rgba(217, 4, 61, 1);
    margin-left: 40%;
  width: 50%;
}
}