@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;200;300;400;500;600;700&family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap');

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    }
  body{
    height: 100vh;
    overflow-X: hidden;
 
    }


    .btn-primary2 {
      position:absolute !important;
      margin-top: -47% !important;
      margin-left:90% !important;
      background-color:white !important;
      width:10% !important;
      height:5.5vh !important;
      padding:.3% !important ;
      padding-left:1% !important ;
      
      border: none !important;
        border-color: white !important; 
    }    
    .btn-primary2:hover{
  border-radius: 2px !important;

    }
    .container{
    position: relative;

}
.landingimg{
    width: 100%;

}
.landing1mb{
  width: 0%;

}
.kalogo{
position: absolute;
margin-top: -47.2%;
width: 18%;
margin-left: 7.3%;

}
.kalogo2{
  width: 0%;
  }
.txt1{
  position: absolute;
  margin-top: -33.4%;
  margin-left: 8%;
  font-style: Regular;
  font-family: 'Barlow Condensed',sans-serif;
  font-size: 2.2vw;

  text-align: left;
  color: #343434 ;
}
.txt11{
  position: absolute;
  margin-top: -31%;
  margin-left: 8%;
  font-style: Regular;
  font-family: 'Barlow Condensed',sans-serif;
  font-size: 2.2vw;

  text-align: left;
  color: #343434 ;
}
.txt2{
  position: absolute;
  margin-top: -30%;
  margin-left: 7.6%;
font-weight: bold;
  font-family: 'Raleway',sans-serif;
  font-size: 6.2vw;
  text-align: center;
  color:rgba(37, 55, 89, 1);
}
.txt3{
  color:rgba(217, 4, 61, 1);
}
.txt4{
  position: absolute;
  margin-top: -22.5%;
  margin-left: 8%;
font-weight:400;
font-family: 'Barlow Condensed',sans-serif;
font-size: 1.5vw;
  line-height: 24px;
  line-height: 100%;
  text-align: left;
  color:#343434;
}

.letdiscus{
  position: absolute;
  margin-top: -18%;
  margin-left: 8%;
font-weight:600;
font-family: 'Raleway',sans-serif;
font-size: 1.4vw;
padding-top: .7%;
padding-bottom: .7%;
  text-align: center;
  color:#FFFFFF;
background-color: rgba(217, 4, 61, 1);

width: 20%;

}
.letlink{
  color: #FFFFFF;
}
.letlink:hover{
  color: #FFFFFF;
  font-size: 1.5vw;
}
.float-container{
  margin-top: -1.2%;
  background-color: rgba(217, 4, 61, 1);
  width: 100%;
padding-top: .3%;
padding-bottom: 13.5%;
}
.float-child {
  width: 33%;
  float: left;
   
}  
.log1{
  width: 8%;
  margin-top: 10%;
  margin-left: 38%;
}
.ftxt1{
  margin-top: -9%;
  margin-left: 48.3%;
  font-family: 'Raleway',sans-serif;
 font-weight: 600;
  font-size: 1.8vw;
  color: #FFFFFF;
}
.ftxt2{
  margin-top: -1%;
  margin-left: 49%;
  font-family: 'Barlow Condensed',sans-serif;

 font-weight: 400;
  font-size: 1.1vw;
  color: #FFFFFF;
  text-align: left;
}
.log2{
  width: 8%;
  margin-top: 9%;
  margin-left: 16.5%;
}
.ftxt3{
  margin-top: -7.5%;
  margin-left: 26.7%;
  font-family: 'Raleway',sans-serif;
 font-weight: 600;
  font-size: 1.8vw;
  color: #FFFFFF;
}
.ftxt4{
  margin-top: -1%;
  margin-left: 27.5%;
  font-family: 'Barlow Condensed',sans-serif;

 font-weight: 400;
  font-size: 1.1vw;
  color: #FFFFFF;
  text-align: left;
}
.log3{
  width: 8%;
  margin-top: 9%;
  margin-left: -3%;
}
.ftxt5{
  margin-top: -9%;
  margin-left: 7%;
  font-family: 'Raleway',sans-serif;
 font-weight: 600;
  font-size: 1.8vw;
  color: #FFFFFF;
}
.ftxt6{
  margin-top: -1%;
  margin-left: 7.5%;
  font-family: 'Barlow Condensed',sans-serif;

 font-weight: 400;
  font-size: 1.1vw;
  color: #FFFFFF;
  text-align: left;
}
.slidecontainer{
width: 0%;
}
@media only screen and (max-width: 2800px) {
  .btn-primary2 {
     height:6.5vh !important;
   
  }
}
@media only screen and (max-width: 2000px) {
  .btn-primary2 {
     height:5.4vh !important;
   
  }
}
@media only screen and (max-width: 800px) {
.mtext{
  font-size: 0px;
}
.m1,.m2,.m3{
  width: 0px;
}
.btn-primary2 {
  position:absolute !important;
  margin-top: 0% !important;
  margin-left:0% !important;
  background-color:white !important;
  width:0% !important;
  height:0vh !important;
  padding:0% !important ;
  border: none !important;
}


}


@media only screen and (max-width: 750px) {
  .landingimg{
    width: 0%;
  
  } 
.landing1mb{
  width: 100%;

}
  .kalogo{
    width: 0%;
    
    }
  .kalogo2{
    position: absolute;
    margin-top: -63.2%;
    width: 10%;
    margin-left: 7.3%;
    
    }
  .landing1{
    width: 100%;

}
  .float-container{
   width: 0%;
  }
  .slidecontainer{
    width: 100%;
  margin-top: -17%;  
  }
  .bgimage{
    position: relative;
    background-color: #D9043D;
    width: 100%;
    height: 15vh;
  }
  .logcaursol{
    position: absolute;
     margin-top: -25%;
     margin-left: -45%;
     width: 10%;

   }
   .caursoltxt11{
    position: absolute;
    margin-top: -26%;
    margin-left: 18.3%;
    font-family: 'Raleway',sans-serif;
   font-weight: 600;
   text-shadow: 2px 2px #D9043D;

    font-size: 5vw;
    margin-bottom: 2%;
    color: #FFFFFF;
  }
  .causrsoltxt2{
position: absolute;
margin-top: -16%;
    margin-left: 18.3%;
    text-shadow: 2px 2px #D9043D;
    font-family: 'Barlow Condensed',sans-serif;
  
   font-weight: 400;
    font-size: 3vw;
    color: #FFFFFF;
    text-align: left;
  }  
  .causrsoltxt22{
    position: absolute;
    margin-top: -10%;
        margin-left: 18.3%;
        font-family: 'Barlow Condensed',sans-serif;
        text-shadow: 2px 2px #D9043D;
       font-weight: 400;
        font-size: 3vw;
        color: #FFFFFF;
        text-align: left;
      }  
      .causrsoltxt222{
        position: absolute;
        margin-top: -5%;
            margin-left: 18.3%;
            font-family: 'Barlow Condensed',sans-serif;
            text-shadow: 2px 2px #D9043D;
           font-weight: 400;
            font-size: 3vw;
            color: #FFFFFF;
            text-align: left;
          }  
          .txt1{
            position: absolute;
            margin-top: -42.5%;
            font-size: 3vw;
          
          }
          .txt11{
             margin-top: -39%;
             font-size: 3vw;
          
            }
          .txt2{
            margin-top: -37%;
            font-size: 8vw;
          }
          .txt3{
            color:rgba(217, 4, 61, 1);
          }
          .txt4{
            position: absolute;
            margin-top: -27.5%;
            margin-left: 8%;
          font-size: 2.5vw;
          }
          
          .letdiscus{
            margin-top: -19%;
          font-size: 2vw;
          padding-top: 1%;
          padding-bottom: 1%;
          width: 27%;
          
          }

        }
  @media only screen and (max-width: 320px) {
    .logcaursol{
      position: absolute;
       margin-top: -18%;
       margin-left: -45%;
       width: 10%;
  
     }
     .caursoltxt11{
      position: absolute;
      margin-top: -19%;
      margin-left: 18.3%;
      font-family: 'Raleway',sans-serif;
     font-weight: 600;
     text-shadow: 2px 2px #D9043D;
  
      font-size: 5vw;
      margin-bottom: 2%;
      color: #FFFFFF;
    }
    .causrsoltxt2{
  position: absolute;
  margin-top: -9%;
      margin-left: 18.3%;
      text-shadow: 2px 2px #D9043D;
      font-family: 'Barlow Condensed',sans-serif;
    
     font-weight: 400;
      font-size: 3vw;
      color: #FFFFFF;
      text-align: left;
    }  
    .causrsoltxt22{
      position: absolute;
      margin-top: -3%;
          margin-left: 18.3%;
          font-family: 'Barlow Condensed',sans-serif;
          text-shadow: 2px 2px #D9043D;
         font-weight: 400;
          font-size: 3vw;
          color: #FFFFFF;
          text-align: left;
        }  
        .causrsoltxt222{
          position: absolute;
          margin-top: 2%;
              margin-left: 18.3%;
              font-family: 'Barlow Condensed',sans-serif;
              text-shadow: 2px 2px #D9043D;
             font-weight: 400;
              font-size: 3vw;
              color: #FFFFFF;
              text-align: left;
            }  
   
  }
  @media only screen and (max-width: 220px) {
    .logcaursol{
      position: absolute;
       margin-top: -14%;
       margin-left: -45%;
       width: 10%;
  
     }
     .caursoltxt11{
      position: absolute;
      margin-top: -15%;
      margin-left: 18.3%;
      font-family: 'Raleway',sans-serif;
     font-weight: 600;
     text-shadow: 2px 2px #D9043D;
  
      font-size: 5vw;
      margin-bottom: 2%;
      color: #FFFFFF;
    }
    .causrsoltxt2{
  position: absolute;
  margin-top: -5%;
      margin-left: 18.3%;
      text-shadow: 2px 2px #D9043D;
      font-family: 'Barlow Condensed',sans-serif;
    
     font-weight: 400;
      font-size: 3vw;
      color: #FFFFFF;
      text-align: left;
    }  
    .causrsoltxt22{
      position: absolute;
      margin-top: 1%;
          margin-left: 18.3%;
          font-family: 'Barlow Condensed',sans-serif;
          text-shadow: 2px 2px #D9043D;
         font-weight: 400;
          font-size: 3vw;
          color: #FFFFFF;
          text-align: left;
        }  
        .causrsoltxt222{
          position: absolute;
          margin-top: 6%;
              margin-left: 18.3%;
              font-family: 'Barlow Condensed',sans-serif;
              text-shadow: 2px 2px #D9043D;
             font-weight: 400;
              font-size: 3vw;
              color: #FFFFFF;
              text-align: left;
            }  
   
  }
  @media only screen and (max-width: 190px) {
    .logcaursol{
      position: absolute;
       margin-top: -13%;
       margin-left: -45%;
       width: 10%;
  
     }
     .caursoltxt11{
      position: absolute;
      margin-top: -14%;
      margin-left: 18.3%;
      font-family: 'Raleway',sans-serif;
     font-weight: 600;
     text-shadow: 2px 2px #D9043D;
  
      font-size: 5vw;
      margin-bottom: 2%;
      color: #FFFFFF;
    }
    .causrsoltxt2{
  position: absolute;
  margin-top: -4%;
      margin-left: 18.3%;
      text-shadow: 2px 2px #D9043D;
      font-family: 'Barlow Condensed',sans-serif;
    
     font-weight: 400;
      font-size: 3vw;
      color: #FFFFFF;
      text-align: left;
    }  
    .causrsoltxt22{
      position: absolute;
      margin-top: 2%;
          margin-left: 18.3%;
          font-family: 'Barlow Condensed',sans-serif;
          text-shadow: 2px 2px #D9043D;
         font-weight: 400;
          font-size: 3vw;
          color: #FFFFFF;
          text-align: left;
        }  
        .causrsoltxt222{
          position: absolute;
          margin-top: 7%;
              margin-left: 18.3%;
              font-family: 'Barlow Condensed',sans-serif;
              text-shadow: 2px 2px #D9043D;
             font-weight: 400;
              font-size: 3vw;
              color: #FFFFFF;
              text-align: left;
            }  
   
  }
  @media only screen and (max-width: 150px) {
    .logcaursol{
      position: absolute;
       margin-top: 1%;
       margin-left: -45%;
       width: 10%;
  
     }
     .caursoltxt11{
      position: absolute;
      margin-top: 0%;
      margin-left: 18.3%;
      font-family: 'Raleway',sans-serif;
     font-weight: 600;
     text-shadow: 2px 2px #D9043D;
  
      font-size: 5vw;
      margin-bottom: 2%;
      color: #FFFFFF;
    }
    .causrsoltxt2{
  position: absolute;
  margin-top: 10%;
      margin-left: 18.3%;
      text-shadow: 2px 2px #D9043D;
      font-family: 'Barlow Condensed',sans-serif;
    
     font-weight: 400;
      font-size: 3vw;
      color: #FFFFFF;
      text-align: left;
    }  
    .causrsoltxt22{
      position: absolute;
      margin-top: 16%;
          margin-left: 18.3%;
          font-family: 'Barlow Condensed',sans-serif;
          text-shadow: 2px 2px #D9043D;
         font-weight: 400;
          font-size: 3vw;
          color: #FFFFFF;
          text-align: left;
        }  
        .causrsoltxt222{
          position: absolute;
          margin-top:21%;
              margin-left: 18.3%;
              font-family: 'Barlow Condensed',sans-serif;
              text-shadow: 2px 2px #D9043D;
             font-weight: 400;
              font-size: 3vw;
              color: #FFFFFF;
              text-align: left;
            }  
   
  }
